import styled from "@emotion/styled";
import { Alert, Button, Fade, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import LockClockIcon from '@mui/icons-material/LockClock';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { APICodes } from "../components/API";
import { routeCodes } from "../components/Routes";
import { parseJwt } from "../utils";

const RootStyle = styled("div")({
    /*   background: "rgb(249, 250, 251)",*/
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const HeadingStyle = styled(Box)({
    textAlign: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    /*  background: "#fff",*/
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.6, ease: easing },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: easing,
        },
    },
};

// Login.jsx
export const LoginPage = () => {
    const [readOnly, setReadOnly] = useState(true);//disattiva memorizzazione e autofill password
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [reason, setReason] = useState('');
    const navigate = useNavigate();
    const [token, setToken] = useLocalStorage("token", undefined);
    const [uid_cliente, setuid_cliente] = useLocalStorage("uid_cliente", undefined);

    function loginRequest() {
        const login = APICodes.LOGIN+'?app=presenze';

        fetch(login, {
            method: "GET",
            headers: {
                jwtusername: username,
                jwtpassword: password
            }
        })
            .then((response) => {
                for (var pair of response.headers.entries()) {
                    console.log(pair[0] + ': ' + pair[1]);
                }

                if (response.status == 200) {
                    return response.json();
                } else {
                    return false;
                }
            })
            .then((data) => {
                console.log("data:" + data);
                setLoading(false);
                // code here //
                if (!data) {
                    setReason('Accesso non riuscito, verifica le credenziali immesse!');
                } else {
                    //Se il dispositivo è stato configurato ed associato ad un'unità locale
                    //viene verificato se l'utente fa parte dello stesso uid cliente
                    
                    if((uid_cliente!=undefined)&&(uid_cliente!='')){
                        let user_uid_cliente = parseJwt(data.token).uid_cliente;
                        if(uid_cliente!==user_uid_cliente){
                            setReason("Utente non abilitato ad accedere a questo dispositivo");
                            setLoading(false); 
                            return;   
                        }
                    }
                    console.debug(data.token);
                    setToken(data.token)
                    navigate(routeCodes.HOME);
                    console.log('login');
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log("ERROR:" + err);
                setReason("Errore di connessione al server, riprovare tra poco o contattare l'assistenza. (" + err + ")");
                setLoading(false);
            });
    }

    function handleClick() {
        setLoading(true);
        setReason('');
        setTimeout(() => {
            loginRequest()
        }, 1000)
    }
    return (
        <RootStyle>
            <Container maxWidth="sm">
                <ContentStyle>
                    <HeadingStyle component="div" {...fadeInUp}>
                        <Typography sx={{ color: "text.secondary", mb: 5 }}>
                            Accedi
                        </Typography>
                    </HeadingStyle>
                    <Stack
                        component="form"
                        sx={{

                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField label="Nome utente" variant="filled" onChange={(e) => (setUsername(e.target.value))} autoComplete="off" readOnly={readOnly}
                            onFocus={() => setReadOnly(false)}
                            onBlur={() => setReadOnly(true)} />
                        <TextField label="Password" variant="filled" type="password" onChange={(e) => (setPassword(e.target.value))} autoComplete="off" readOnly={readOnly}
                            onFocus={() => setReadOnly(false)}
                            onBlur={() => setReadOnly(true)} />
                        {reason != '' ? <Alert severity='error'>{reason}</Alert> : <></>}

                        <Button variant="contained" onClick={handleClick} startIcon={<LockClockIcon />} disabled={loading}>
                            Accedi
                        </Button>
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '100ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <LinearProgress color="secondary" />
                        </Fade>
                    </Stack>
                </ContentStyle>
            </Container>
        </RootStyle>
    )
};