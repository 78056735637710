const APIPath = process.env.REACT_APP_API_PATH;

const APIVersion = 'v1/'
export const APICodes = {
    LOGIN: `${process.env.REACT_APP_API_LPATH}`,
    MESSAGGI: `${APIPath}${APIVersion}messaggi`,
    PRESENZE: `${APIPath}${APIVersion}presenze`,
    ENTRATA_SOSPESA: `${APIPath}${APIVersion}presenze/entrata/sospesa`,
    INFO_CLIENTE: `${APIPath}${APIVersion}cliente/`,
    INFO_UNITA: `${APIPath}${APIVersion}presenze/info/unit`,
    CAMBIO_PASS: `${APIPath}${APIVersion}utente/password`,

};