import { Alert, Box, Button,  Container, Fab, Fade, LinearProgress, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../components/LogoutButton";
import { DateTime1 } from "../components/DateTime";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { TimbraturaEntrataSospesa } from "../components/TimbraturaEntrataSospesa";
import { routeCodes } from "../components/Routes";
import { APICodes } from "../components/API";
import SettingsIcon from '@mui/icons-material/Settings';
import PasswordIcon from '@mui/icons-material/Password';

const fabStyle = {
  position: 'absolute',
  bottom: 80,
  right: 16,
};

const fabStyle2 = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabStyleTop = {
  position: 'absolute',
  top: 16,
  right: 16,
};

const logoutBtnStyle = {
  position: 'absolute',
  bottom: 16,
  left: 16,
};

const RootStyle = styled("div")({
  /*   background: "rgb(249, 250, 251)",*/
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  /*  background: "#fff",*/
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

export const HomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [reason, setReason] = useState('');
  const [success, setSuccess] = useState('');
  const [entrataSospesa, setEntrataSospesa] = useState(null);
  const [token, setToken] = useLocalStorage("token", undefined);
  const [id_azienda, setIdAzienda] = useLocalStorage("id_azienda", undefined);
  const [id_unita, setIdUnita] = useLocalStorage("id_unita", undefined);

  const [verso, setVerso] = useState('');
  const [note, setNote] = useState('');

  useEffect(()=>{
    if(id_azienda==undefined){
      navigate(routeCodes.SETTINGS);
    }else{
      GetEntrataSospesa();
    }
  },[]);

  const handleChangeVerso = (event, newVerso) => {
    setVerso(newVerso);
  };

  function GetEntrataSospesa() {
    let body = {
        "id_azienda": id_azienda,
        "id_unita": id_unita,
      };

    const url = APICodes.ENTRATA_SOSPESA; 

    fetch(url, {
        method: "POST",
        headers: {
            authorization: 'bearer '+token
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            if (response.status == 200) {
                return response.json();
            } else {
                return false
            }
        })
        .then((data) => {
            setLoading(false);
            console.log(data);
            if (data){
              setEntrataSospesa(data);
              setVerso('U');
            }else{
              setEntrataSospesa(null);
            }
        })
        .catch((err) => {
          console.log(err);
            setLoading(false);
        });    
  }
  function Logout() {
    setToken('');
    navigate(routeCodes.BASE);
    setLoading(false);
  }

  const handleInvia = () => {
    function dateToISOButLocal(date) {
        return date.toLocaleString('sv').replace(' ', 'T')+'Z';
    }
    const dt = new Date();
    let body = {};

    console.log(entrataSospesa);
    console.log(verso);
    if((entrataSospesa!=null)&&(verso==='U')){
      //uscita con timbratura sospesa
      body = {
        "timbratura": {
          "uid": entrataSospesa.timbratura.uid,
          "data_uscita": dateToISOButLocal(dt),
          "verso": verso,
          "note": note
        }
      } 
    }else if((entrataSospesa==null)&&(verso==='U')){
      //uscita non collegata a timbratura sospesa di entrata
      body = {
        "timbratura": {
          "id_azienda": id_azienda,
          "id_unita": id_unita,
          "data_uscita": dateToISOButLocal(dt),
          "verso": verso,
          "tipo_timbratura": 0,
          "note": note
        }
      } 
    }else if (verso==='E'){
      body = {
        "timbratura": {
          "id_azienda": id_azienda,
          "id_unita": id_unita,
          "data_entrata": dateToISOButLocal(dt),
          "verso": verso,
          "tipo_timbratura": 0,
          "note": note
        }
      }
    }else{
        setReason("Scelta del verso timbratura errato.");
        setLoading(false);
        return
    }

    setLoading(true);

    const url = APICodes.PRESENZE;

    fetch(url, {
        method: "POST",
        headers: {
            authorization: 'bearer '+token
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            //if (response.status == 201) {
            //    return response.json();
            //} else {
                return response.json();
            //}
        })
        .then((data) => {
            setLoading(false);
            // code here //
            if (data.message != undefined) {
              setReason(data.message);
            } else {                       
                setSendDisabled(true);
                setSuccess('Timbratura inserita con successo!');
                setTimeout(() => {                  
                  Logout();
                }, 3000);
            }
            setLoading(false);
        })
        .catch((err) => {
            setReason("Errore di connessione al server, riprovare tra poco o contattare l'assistenza. ("+err+")");
            setLoading(false);
        });
  };

  return <>
    
    <LogoutButton style={logoutBtnStyle} />
    <Fab style={fabStyle} color="secondary" aria-label="edit" onClick={() => { navigate(routeCodes.MESSAGE); }}><ChatIcon /></Fab>
    <Fab style={fabStyle2} color="info" aria-label="edit" onClick={() => { navigate(routeCodes.SETTINGS); }}><SettingsIcon /></Fab>
    <Fab style={fabStyleTop} color="warning" aria-label="edit" onClick={() => { navigate(routeCodes.CAMBIOPASS); }}><PasswordIcon /></Fab>

    <Container maxWidth="sm">
      <ContentStyle>
        <HeadingStyle component="div" {...fadeInUp}>
          {/*  <Logo />*/}

          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Invia timbratura
          </Typography>
          {(entrataSospesa != null)&&(verso!=='E') ?  <TimbraturaEntrataSospesa data={entrataSospesa.timbratura} /> : <></>}
          <DateTime1 />
        </HeadingStyle>
        <Stack
          component="form"
          sx={{

          }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <ToggleButtonGroup size="large" color="primary" style={{width:"100%",textAlign:"center"}}
            value={verso}
            exclusive
            onChange={handleChangeVerso}>
            <ToggleButton value="E" color="info" style={{width:"100%",textAlign:"center"}}><LoginIcon /> Ingresso</ToggleButton >
            <ToggleButton value="U" style={{width:"100%",textAlign:"center"}}><LogoutIcon /> Uscita</ToggleButton >
          </ToggleButtonGroup>
          <TextField
            id="outlined-multiline-static"
            label="Note"
            multiline
            rows={2}
            defaultValue=""
            onChange={(e)=>{setNote(e.target.value.substring(0,2000))}}
          />
          {reason != '' ? <Alert severity='error'>{reason}</Alert> : <></>}
          {success != '' ? <Alert severity='success'>{success}</Alert> : <></>}
          <Stack direction="row" spacing={2}>
            <Button variant="contained" startIcon={<ArrowBackIosIcon />} disabled={loading} style={{ width: "40%" }} color="secondary" onClick={Logout}>
              Annulla
            </Button>
            <Button variant="contained" startIcon={<SendIcon />} disabled={loading || (verso == '') || sendDisabled} style={{ width: "60%" }} onClick={handleInvia}>
              Invia
            </Button>
          </Stack>
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '10ms' : '0ms',
            }}
            unmountOnExit
          >
            <LinearProgress color="secondary" />
          </Fade>
        </Stack>
      </ContentStyle>
    </Container>
  </>
};