import { Alert, Button,Box, Fade, LinearProgress,TextField, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styled from "@emotion/styled";
import { Navigate, useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import { useLocalStorage } from "../hooks/UseLocalStorage";
import { routeCodes } from "../components/Routes";
import { APICodes } from "../components/API";
import { LogoutButton } from "../components/LogoutButton";

const RootStyle = styled("div")({
    /*   background: "rgb(249, 250, 251)",*/
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const HeadingStyle = styled(Box)({
    textAlign: "center",
});

const ContentStyle = styled("div")({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    /*  background: "#fff",*/
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.6, ease: easing },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: easing,
        },
    },
};

const logoutBtnStyle = {
    position: 'absolute',
    top: 16,
    right: 16,
};

export const CambioPassword = () => {
    const [token, setToken] = useLocalStorage("token", undefined);

    const [pwd1, setPwd1] = useState(''); 
    const [pwd2, setPwd2] = useState(''); 
    const [reason, setReason] = useState(''); 
    const [pwdChk, setPwdChk] = useState(''); 
    const [success, setSuccess] = useState(''); 
    const [loading, setLoading] = useState(false); 

    const navigate = useNavigate();

const handleInvia = () => {
        setReason('');
        setSuccess('');

        let body = {
            "password": pwd1
        }

        const url = APICodes.CAMBIO_PASS;
        setLoading(true);
        fetch(url, {
            method: "POST",
            headers: {
                authorization: 'bearer ' + token
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                //if (response.status == 201) {
                //    return response.json();
                //} else {
                return response.json();
                //}
            })
            .then((data) => {
                setLoading(false);
                // code here //
                if (data.message != undefined) {
                    setReason(data.message);
                } else {

                    setSuccess('Password aggiornata! Verrai discconnesso della sessione.');
                    setTimeout(() => {                        
                        setPwd1('');
                        setPwd2('');
                        Logout();
                    }, 3000);
                }
                setLoading(false);
            })
            .catch((err) => {
                setReason("Errore di connessione al server, riprovare tra poco o contattare l'assistenza. (" + err + ")");
                setLoading(false);
            });
    };

    const checkPassword = () => {
        if(pwd1.trim().length<8){
            setPwdChk('Inserisci una password valida!')
        }else
        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(pwd1)){
            setPwdChk('Password non valida, deve essere di almeno 8 caratteri, contenere una lettera maiuscola, una minuscola un numero ed un carattere speciale!')
        }else        
        if(pwd1!==pwd2){
            setPwdChk('Le password inserite non corrispondono!')
        }else{
            setPwdChk('');
        }
    }

    function Logout() {
        navigate(routeCodes.BASE);        
        setLoading(false);
    }

    useEffect(()=>{
        checkPassword();  
    },[pwd1,pwd2])

    return <>
        <RootStyle>
            <Container maxWidth="sm">
                <ContentStyle>
                    <HeadingStyle component="div" {...fadeInUp}>
                        <Typography sx={{ color: "text.secondary", mb: 5 }}>
                            Modifica la tua Password
                        </Typography>
                    </HeadingStyle>
                    <Stack
                        component="form"
                        sx={{

                        }}
                        spacing={2}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            color="info"
                            label="Inserisci la nuova password"
                            type="password"
                            onChange={(e) => { setPwd1(e.target.value) }}
                            value={pwd1}                            
                        />
                        <TextField
                            color="info"
                            label="Conferma la password inserita"
                            type="password"
                            onChange={(e) => { setPwd2(e.target.value) }}
                            value={pwd2}
                        />
                        {reason != '' ? <Alert severity='error'>{reason}</Alert> : <></>}
                        {success != '' ? <Alert severity='success'>{success}</Alert> : <></>}
                        {pwdChk != '' ? <Alert severity='warning'>{pwdChk}</Alert> : <></>}
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<ArrowBackIosIcon />} disabled={loading} style={{ width: "40%" }} color="secondary" onClick={() => { navigate(routeCodes.HOME) }}>
                                Esci
                            </Button>
                            <Button variant="contained" startIcon={<SaveIcon />} disabled={loading || pwdChk!==''} style={{ width: "60%" }} onClick={handleInvia} >
                                Aggiorna Password
                            </Button>
                        </Stack>
                        <Fade
                            in={loading}
                            unmountOnExit
                        >
                            <LinearProgress color="secondary" />
                        </Fade>
                    </Stack>
                </ContentStyle>
            </Container>
        </RootStyle></>
}